import { ref, watch, nextTick } from 'vue';
import { getAuthCodeList } from '@/service/index';
import { ElLoading } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { alertMessage, updateTextFormat } from '@/assets/js/tool.js';
import clipboard from 'vue-clipboard3';
import CreateAuthorization from '@/components/Dialog/CreateAuthorization.vue';
import IdeTable from '@/components/MyTable/IdeTable.vue';
export default {
  components: {
    CreateAuthorization,
    IdeTable
  },
  setup() {
    //data
    const {
      t
    } = useI18n();
    const visible = ref(false);
    const page = ref(1); //当前分页
    const total = ref(1); //共多少页
    const noMore = ref(false); //没有更多数据
    const list = ref([]);
    const column = ref([{
      title: () => t('message.GenerationDate'),
      width: '160px',
      template: row => {
        return row.createTime ? row.createTime.split(' ')[0] : '';
      }
    }, {
      title: () => t('message.MAC'),
      width: '280px',
      template: row => {
        return row.mac ? row.mac : '';
      }
    }, {
      title: () => t('message.Deadline'),
      width: '180px',
      template: row => {
        return row.endDate ? row.endDate : '一';
      }
    }, {
      title: () => t('message.AuthorizationDays'),
      width: '180px',
      template: row => {
        return typeof row.day === 'number' ? row.day == -1 ? t('message.Perpetual') : row.day : '';
      }
    }, {
      title: () => t('message.AuthorizationCode'),
      template: row => {
        return row.code ? updateTextFormat(row.code, 4, '-') : '';
      }
    }, {
      title: '',
      width: '100px',
      operation: () => t('message.Copy')
    }]);
    const {
      toClipboard
    } = clipboard();

    //ref
    const ideTableRef = ref(null);

    //watch
    watch(() => list.value, async () => {
      await nextTick();
      ideTableRef.value.initEllipsis();
      ideTableRef.value.scrollDisabled = false;
    });

    //created
    getAuthList();

    //function
    /**获取授权列表*/
    async function getAuthList(params = {}) {
      noMore.value = false;
      params.page = page.value;
      params.rows = 20;
      params.S_createTime = 'desc';
      let loading = ElLoading.service({
        lock: true,
        background: 'rgba(0, 0, 0, 0)'
      });
      let result = await getAuthCodeList(params);
      loading.close();
      if (result && result.status === 0) {
        total.value = result.total || 1;
        let rows = result.rows || [];
        list.value = [...list.value, ...rows];
      } else {
        let text = result ? result.msg || t('failed.getData') : t('failed.getData');
        alertMessage('error', text);
      }
    }

    /**创建授权码*/
    function handleAddSuccess() {
      visible.value = false;
      page.value = 1;
      list.value = [];
      ideTableRef.value.initScrollTop();
      getAuthList();
    }

    /**点击复制*/
    async function handleClickCopy(row) {
      let value = row.code;
      try {
        value = updateTextFormat(value, 4, '-');
        await toClipboard(value);
        alertMessage('success', t('success.Copied'));
      } catch (e) {
        alertMessage('error', t('failed.Copied'));
      }
    }

    //加载更多
    function handleLoadMore() {
      page.value++;
      if (page.value > total.value) {
        noMore.value = true;
        return;
      }
      getAuthList();
    }
    return {
      //data
      visible,
      list,
      column,
      noMore,
      //ref
      ideTableRef,
      //function
      handleAddSuccess,
      handleClickCopy,
      updateTextFormat,
      handleLoadMore
    };
  }
};