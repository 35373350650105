import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  class: "ide-table"
};
const _hoisted_2 = {
  class: "ide-table-header"
};
const _hoisted_3 = {
  class: "ide-table-list"
};
const _hoisted_4 = ["infinite-scroll-disabled"];
const _hoisted_5 = {
  key: 0,
  class: "ide-table-more"
};
const _hoisted_6 = ["innerHTML"];
const _hoisted_7 = {
  key: 0,
  class: "more-content-right"
};
const _hoisted_8 = {
  key: 0,
  class: "one-row"
};
const _hoisted_9 = ["innerHTML", "title"];
const _hoisted_10 = {
  key: 1,
  class: "operation"
};
const _hoisted_11 = {
  key: 0,
  class: "no-more"
};
const _hoisted_12 = {
  key: 1,
  class: "no-date"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_arrow_right = _resolveComponent("arrow-right");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_el_link = _resolveComponent("el-link");
  const _directive_infinite_scroll = _resolveDirective("infinite-scroll");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [$props.column.length ? (_openBlock(true), _createElementBlock(_Fragment, {
    key: 0
  }, _renderList($props.column, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["ide-table-header-item", {
        'flex-1': !item.width
      }]),
      style: _normalizeStyle({
        width: item.width
      }),
      key: index
    }, _toDisplayString(typeof item.title === 'function' ? item.title() : item.title), 7);
  }), 128)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_3, [_withDirectives((_openBlock(), _createElementBlock("div", {
    class: "ide-srcoll-wrap",
    style: {
      "overflow": "auto"
    },
    "infinite-scroll-distance": 60,
    "infinite-scroll-disabled": $data.scrollDisabled,
    "infinite-scroll-immediate": false
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, (row, rowIndex) => {
    return _openBlock(), _createElementBlock("div", {
      class: "ide-table-list-row",
      style: _normalizeStyle({
        height: $data.rowHeightArr[rowIndex] + 'px'
      }),
      key: rowIndex,
      ref_for: true,
      ref: "tableRow"
    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.column, (item, ItemIndex) => {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["list-row-item", {
          'flex-1': !item.width
        }]),
        style: _normalizeStyle({
          width: item.width
        }),
        key: ItemIndex
      }, [item.more ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("div", {
        class: "more-content",
        ref_for: true,
        ref: "moreContentRef"
      }, [_createElementVNode("div", {
        class: _normalizeClass(["more-content-left", {
          'ellipsis-3': $data.moreHeightArr[rowIndex] > 72,
          'text-center': $data.moreHeightArr[rowIndex] == 72
        }])
      }, [item.template ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        innerHTML: item.template(row)
      }, null, 8, _hoisted_6)) : _createCommentVNode("", true)], 2), $data.moreHeightArr[rowIndex] > 72 ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_el_link, {
        type: "primary",
        onClick: $event => $options.lookMore(rowIndex)
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('message.more2')) + " ", 1), _createVNode(_component_el_icon, null, {
          default: _withCtx(() => [_createVNode(_component_arrow_right)]),
          _: 1
        })]),
        _: 2
      }, 1032, ["onClick"])])) : _createCommentVNode("", true)], 512)])) : (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [item.template ? (_openBlock(), _createElementBlock("p", _hoisted_8, [_createElementVNode("span", {
        innerHTML: item.template(row),
        title: item.template(row)
      }, null, 8, _hoisted_9)])) : _createCommentVNode("", true), item.operation ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createVNode(_component_el_link, {
        type: "primary",
        class: "forgot-link",
        onClick: $event => $options.handleClickOperation(row)
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(item.operation()), 1)]),
        _: 2
      }, 1032, ["onClick"])])) : _createCommentVNode("", true)], 64))], 6);
    }), 128))], 4);
  }), 128)), $props.noMore ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t('message.NoMore')), 1)) : _createCommentVNode("", true), !$props.list.length ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.$t('message.noData')), 1)) : _createCommentVNode("", true)], 8, _hoisted_4)), [[_directive_infinite_scroll, $options.handleLoadMore]])])]);
}