import "core-js/modules/es.array.push.js";
import { ArrowRight } from '@element-plus/icons-vue';
export default {
  props: {
    //列
    column: {
      type: Array,
      default() {
        return [];
      }
    },
    //数据
    list: {
      type: Array,
      default() {
        return [];
      }
    },
    //行高
    rowHeight: {
      type: Number
    },
    //没有更多数据
    noMore: Boolean
  },
  components: {
    ArrowRight
  },
  data() {
    return {
      rowHeightArr: [],
      //行高
      moreHeightArr: [],
      //隐藏文字盒子高度
      scrollDisabled: false
    };
  },
  methods: {
    //点击自定义操作
    handleClickOperation(row) {
      this.$emit('operation', row);
    },
    //初始化溢出隐藏
    initEllipsis() {
      let rowHeightArr = [];
      let moreHeightArr = [];
      let moreContentRef = this.$refs.moreContentRef;
      if (!moreContentRef) {
        this.list.forEach(() => {
          this.rowHeightArr.push(this.rowHeight);
        });
        return;
      }
      for (let i = 0; i < moreContentRef.length; i++) {
        rowHeightArr[i] = rowHeightArr[i] || this.rowHeight;
        moreHeightArr[i] = 72;
        let $dom = moreContentRef[i];
        let left = $dom.querySelector('.more-content-left');
        let span = left.querySelector('span');
        if (span.offsetHeight > 72) {
          moreHeightArr[i] = span.offsetHeight;
        }
      }
      this.rowHeightArr = rowHeightArr;
      this.moreHeightArr = moreHeightArr;
    },
    //查看更多
    lookMore(index) {
      let moreHeight = this.moreHeightArr[index];
      let rowHeight = this.rowHeightArr[index];
      let newHeight = rowHeight + (moreHeight - 72) + 4;
      this.moreHeightArr[index] = 0;
      this.rowHeightArr[index] = newHeight;
    },
    //加载更多
    handleLoadMore() {
      this.scrollDisabled = true;
      this.$emit('loadMore');
    },
    //初始化滚动条
    initScrollTop() {
      document.querySelector('.ide-srcoll-wrap').scrollTop = top;
    }
  }
};